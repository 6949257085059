import React from "react";
import { Box, Grid, Typography } from '@mui/material';

const Welcome = () => {
  return (
    <Box className="second-container" id="second" sx={{
      width: '90%',
      height: 'auto',
      margin:'auto',
      marginTop:'20px',

      marginBottom:'20px',

      position: 'relative',
    }}>
   
   
        <Grid item xs={12} md={7} lg={8}>
          <div className="foto-text" sx={{
            height: 'auto',
            backgroundColor: '#C4C4C4',
            marginTop: '1.5rem',
            fontFamily: '"Montserrat", sans-serif',
            '@media (min-width: 768px)': {
              marginTop: '0',
            },
          }}>
            <Typography variant="h3" className='welcome-text text-center' sx={{
             
              letterSpacing: '1px',
              fontSize: '16px',
              lineHeight: '30px',
              fontWeight:'500',
              fontFamily: '"Montserrat", sans-serif',
              '@media (min-width: 1024px)': {
                paddingTop: '1.5rem',
                letterSpacing: '1.5px',
                fontSize: '24px',
                width: '70%', 
                margin:'auto'
              },
            }}>
              Varje dag öppnar vi dörrarna till vår skönhetssalong för att ge våra kunder nöjet att vara vackra!
            </Typography>
     
          </div>
        </Grid>

    </Box>
  );
};

export default Welcome;
