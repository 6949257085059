import React from 'react'
import { Box } from '@mui/material'
import Hero from '../components/Hero';
import Services from '../components/Services';
import Welcome from '../components/Welcome';
import Galery from '../components/Galery';
import ImageText from '../components/ImageText';
import EmptyHome from '../components/EmptyHome';
import EmptyHome2 from '../components/EmptyHome2';
import VideoComponent from '../components/VideoComponent';

function Home() {
  return (
    <Box>
        <Hero/>
        <Welcome/>
        <Services/>
        <EmptyHome/>
        <ImageText/>
        <Galery/>
    
        <EmptyHome2/>
        <VideoComponent/>
    </Box>
  )
}

export default Home