import React from "react";
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const FooterContainer = styled(Box)({
  width: '100%',
  height: '5rem',
  backgroundColor: 'grey',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  color: '#d3d3d3',
});

const Footer = () => {
  return (
    <FooterContainer id="contact">
      <a href="https://www.samoteev.dev/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#d3d3d3' }}>
        <Typography variant="body2">
          Beauty Space @ 2024
        </Typography>
      </a>
    </FooterContainer>
  );
}

export default Footer;


