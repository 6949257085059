import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Table, TableBody, TableCell, TableRow, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

// Importing images
import h7 from '../assets/h7.jpg';
import h8 from '../assets/4.jpg';
import b1 from '../assets/b1.jpg';
import Group23Image from '../assets/Group24.png'; 
import bottom from '../assets/bottom.png'; 

// Styled Components

const BackgroundBox = styled(Box)({
  width: '100%',
  height: '200px',
  margin: 'auto',
  position: 'absolute',
  top: '-3%',
  padding:'none',

  backgroundImage: `url(${Group23Image})`,
  backgroundSize: 'inherit', // This ensures the image covers the entire box
  backgroundPosition: 'center center', // Centers the image within the box
  backgroundRepeat: 'no-repeat' // Prevents the image from repeating
});
const BackgroundBoxBottom = styled(Box)({
  width: '100%',
  height: '200px',
  margin: 'auto',
  position: 'absolute',
  bottom: '-3%',
  padding:'none',
  backgroundImage: `url(${bottom})`,
  backgroundSize: 'inherit', // This ensures the image covers the entire box
  backgroundPosition: 'center center', // Centers the image within the box
  backgroundRepeat: 'no-repeat' // Prevents the image from repeating
});

const BoxesIT = styled(Box)({
  width: '100%',
  backgroundColor: '#d3d3d3',
  margin: 0,
  padding: 0,
  position: 'relative',
});

const MyTable = styled(Table)({
  width: '100%',
  minWidth: '100%',
});

const NameSection = styled(Box)({

  marginLeft: '5rem',
  marginRight: '5rem',
  '@media (max-width: 650px)': {
    marginLeft: '5px',
    marginRight: '5px',
  },

});

const Title = styled(Typography)({
  textAlign: 'center',
  paddingBottom: '2rem',
  fontSize: '2rem',
  marginTop: '5rem',
  fontFamily: '"Montserrat", sans-serif',
});

const Paragraph = styled(Typography)({
  fontSize: '18px',
  fontFamily: '"Montserrat", sans-serif',
  color: 'rgb(43, 39, 39)',
  letterSpacing: '0.5px',
  lineHeight: '2rem',
  paddingLeft: '2rem',
  paddingRight: '2rem',
  textAlign: 'justify',
  paddingBottom:'15px',
  '@media (max-width: 650px)': {
    fontSize: '14px',
    lineHeight: '1.5rem',
    margin: 'auto',
    paddingLeft: '0',
    paddingRight: '0',
    width: '100%',
  },
  '@media (min-width: 651px)': {
    fontSize: '12px',
    lineHeight: '1.5rem',
  },
  '@media (min-width: 1000px)': {
    fontSize: '14px',
    lineHeight: '2rem',
    marginTop: '5%',
  },
  '@media (min-width: 1200px)': {
    fontSize: '14px',
    lineHeight: '2rem',
    marginTop: '5%',
  },
  '@media (min-width: 1300px)': {
    fontSize: '18px',
    lineHeight: '2rem',
    marginTop: '5%',
  },
});

const SmallScreenImg = styled('img')({
  display: 'none',
  '@media (max-width: 650px)': {
    display: 'block',
    width: '80%',
    height: 'auto',
    marginLeft: '10%',
  },
});

const LargeScreenImg = styled('img')({
  display: 'none',
  '@media (min-width: 651px)': {
    display: 'block',
    width: '100%',
    height: 'auto',
    marginTop: '5%',
  },
});

const HideOnSmallScreen = styled(TableCell)({
  '@media (max-width: 650px)': {
    display: 'none',
  },
});



const ImageText = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <BoxesIT id="about">
      <BackgroundBox src={Group23Image} alt="Group 23 Image" />
       
      <NameSection >
        <Title variant="h1"></Title>
        <MyTable  >
          <TableBody>
            {/*-------------------------------------------------------- FIRST */}
            <TableRow>
              <TableCell sx={{ textAlign: 'left', paddingLeft: '1rem' }}>
                <SmallScreenImg src={h7} alt="H7 Image" />
                <Paragraph>
                Hårstyling är en lång och komplicerad process om man gör det själv. I de fall då en kvinna behöver se helt fantastisk och felfri ut, spelar en elegant och noggrann hårstyling en mycket viktig roll.

I våra skönhetssalonger stylar vi snabbt och varsamt ditt hår på ett sätt som subtilt och elegant framhäver din skönhet och kvinnlighet. Vi värdesätter en individuell och icke-formell inställning till våra kunder och gör allt vi kan för att utföra vårt arbete snabbt och av hög kvalitet!
                </Paragraph>
              </TableCell>
              <HideOnSmallScreen>
                <LargeScreenImg src={h7} alt="N3 Image" />
              </HideOnSmallScreen>
            </TableRow>
            {/*-------------------------------------------------------- SECOND */}
            <TableRow className='middleCell' sx={{borderBlock:'1px solid grey',boxShadow:'4px 0 2px grey',backgroundColor:'#f5f3f4'}}>
              <HideOnSmallScreen>
                <LargeScreenImg src={h8} alt="H8 Image" />
              </HideOnSmallScreen>
              <TableCell sx={{ textAlign: 'left', paddingRight: '1rem' }}>
                <SmallScreenImg src={h8} alt="H8 Image" />
                <Paragraph>
                Man kan ha olika inställningar till manikyr: att ha beläggning eller inte, att gilla ljusa nyanser eller nude, att välja design eller enfärgat, men en sak förblir oföränderlig - manikyren måste vara! 💅

En välgjord manikyr förbättrar inte bara utseendet på händerna utan bidrar också till självkänslan. Det är ett sätt att uttrycka sin personlighet, oavsett om man föredrar subtil elegans eller livliga färger. Att ta hand om sina naglar är inte bara en fråga om estetik, det är också en viktig del av personlig vård och hygien. Så glöm inte att unna dig en manikyr som passar din stil och humör.
                </Paragraph>
              </TableCell>
            </TableRow>
            {/*-------------------------------------------------------- THREE */}
            <TableRow >
              <TableCell sx={{ textAlign: 'left', paddingLeft: '1rem' }}>
                <SmallScreenImg src={b1} alt="B1 Image" />
                <Paragraph>
                Man kan ha olika inställningar till ögonbryn och ögonfransar: att ha fylliga och välformade bryn eller naturliga och vilda, att gilla långa och böjda fransar eller korta och täta, men en sak förblir oföränderlig - de måste vara välskötta!

Välskötta ögonbryn och ögonfransar ramar in ansiktet och ger ett polerat och attraktivt utseende. Precis som en välgjord manikyr kan välformade ögonbryn och långa, fylliga fransar förbättra självkänslan och lyfta fram ens naturliga skönhet. Oavsett om du föredrar att fylla i dina bryn med en penna eller att använda ett serum för att få längre fransar, är det en viktig del av din skönhetsrutin. Att ta hand om ögonbryn och ögonfransar är inte bara en fråga om estetik, det är också ett uttryck för din personlighet och stil. Så glöm inte att ge dem den vård och uppmärksamhet de förtjänar för att alltid se ditt bästa ut!
                </Paragraph>
              </TableCell>
              <HideOnSmallScreen>
                <LargeScreenImg src={b1} alt="B1 Image" />
              </HideOnSmallScreen>
            </TableRow>
          </TableBody>
        </MyTable>
      </NameSection>
      <BackgroundBoxBottom src={bottom} alt="bottom" />
    </BoxesIT>
  );
};

export default ImageText;

