import { Routes, Route } from "react-router-dom";
import './App.css';
import Home from "./pages/Home";
import { Box } from "@mui/material";
import DrawerAppBar from "./components/DrawerAppBar";
import Footer from "./components/Footer";

function App() {
  return (
   
    <Box className="App">
          <DrawerAppBar />
          <Routes>
            <Route path="/" element={<Home />} />
            
          </Routes>
          <Footer/>
        </Box>
  );
}

export default App;
