import React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

// Import images
import salonImage from '../assets/1.jpg';  
import p1Image from '../assets/2.jpg';
import p2Image from '../assets/h2.jpg';
// import p3Image from '../assets/n2.jpg';
import friendImage from '../assets/3.jpg';
import diplomImage from '../assets/4.jpg';
import max1Image from '../assets/5.jpg';
import image1Image from '../assets/n3.jpg';
import image2 from '../assets/6.jpg';
import image3 from '../assets/7.jpg';
import image4 from '../assets/n2.jpg';
import image5 from '../assets/h8.jpg';
import image6 from '../assets/8.jpg';
import { Box } from '@mui/material';

const itemData = [
  { img: salonImage, title: 'Salon', rows: 2, cols: 2 },
  { img: p1Image, title: 'P1', rows: 2, },
  { img: p2Image, title: 'P2', rows: 2 },
  // { img: p3Image, title: 'P3', cols: 2, },
  { img: friendImage, title: 'Friend',rows: 2  },
  { img: diplomImage, title: 'Diplom', cols: 2 ,rows: 3,},
  { img: max1Image, title: 'Max1' ,rows: 4},
  { img: image1Image, title: 'Image1',rows: 3 },
  { img: image2, title: 'Image2', rows: 4, cols: 2 },
  { img: image3, title: 'Image3', rows: 3 },
  { img: image4, title: 'Image4',rows: 2 },
  { img: image5, title: 'Image5', cols: 2,rows: 3  },
  { img: image6, title: 'Image6',rows: 3,cols: 2},
];

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format&dpr=2 2x`,
  };
}

const Gallery = () => {
  return (
    <Box sx={{marginBlock:'50px'}}>
    <ImageList
    sx={{ width: '90%', height:'auto',margin:'auto',gap: 3, }}
      variant="quilted"
      cols={4}
      rowHeight={121}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1} sx={{border:'1px solid grey', borderRadius:'2px' }}>
          <img
            {...srcset(item.img, 121, item.rows, item.cols)}
            alt={item.title}
            loading="lazy"
            style={{ minHeight: 'auto', objectFit: 'cover' }}
          />
        </ImageListItem>
      ))}
    </ImageList>
    </Box>
  );
};

export default Gallery;
