import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import backgroundImage from "../assets/blond1.png"; // Import your image

const HeroContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    marginTop: '50px',
    minHeight: '40vh',
    backgroundColor: '#171717',
    display: 'flex',
    backgroundImage: `url(${backgroundImage})`, // Use imported image
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    [theme.breakpoints.up('sm')]: {
      height: '50vh',
    },
    [theme.breakpoints.up('md')]: {
      height: '60vh',
    },
    [theme.breakpoints.up('lg')]: {
      height: '80vh',
    },
  }));
  
  const HeaderText = styled(Box)(({ theme }) => ({
    color: 'rgb(190, 191, 192)',
    position: 'absolute',
    top: '10%',
    right: '7%',
  }));
  
//   const Title = styled(Typography)(({ theme }) => ({
//       letterSpacing: '2px',
//       fontSize: '45px',
//       lineHeight: '45px',
//       textAlign: 'right',
//       marginLeft:'auto',
//       fontFamily: 'Arizonia, cursive', // Updated font family to Arizonia
//       fontWeight: 400, // Updated font weight to 400
//       fontStyle: 'normal', 
//       // backgroundColor:'rgb(23, 23, 23, 0.1)',
//       backdropFilter: 'blur(5px)',
//       borderBottom:'1px solid #535151'
// ,      [theme.breakpoints.up('sm')]: {
//         letterSpacing: '5px',
//         fontSize: '55px',
//         lineHeight: '5rem',
//       },
//       [theme.breakpoints.up('md')]: {
//         letterSpacing: '8px',
//         fontSize: '75px',
//         lineHeight: '6rem',
//       },
//       [theme.breakpoints.up('lg')]: {
//         letterSpacing: '6px',
//         fontSize: '7rem',
//         lineHeight: '10rem',
//       },
//     }));

const Title = styled(Typography)(({ theme }) => ({
  letterSpacing: '2px',
  fontSize: '45px',
  lineHeight: '45px',
  textAlign: 'right',
  marginLeft: 'auto',
  fontFamily: 'Arizonia, cursive', // Updated font family to Arizonia
  fontWeight: 400, // Updated font weight to 400
  fontStyle: 'normal',
  // backdropFilter: 'blur(1px)',
  borderBottom: '1px solid #535151',
  [theme.breakpoints.up('sm')]: {
    letterSpacing: '5px',
    fontSize: '55px',
    lineHeight: '5rem',
  },
  [theme.breakpoints.up('md')]: {
    letterSpacing: '8px',
    fontSize: '75px',
    lineHeight: '6rem',
  },
  [theme.breakpoints.up('lg')]: {
    letterSpacing: '6px',
    fontSize: '7rem',
    lineHeight: '10rem',
  },
}));

const gradientBackgroundStyle = {
  background: 'linear-gradient(90deg, #333, #ccc)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
};


  const SmallText = styled(Typography)(({ theme }) => ({
      width: '15rem',
      textAlign: 'center',
      fontFamily: '"Montserrat", sans-serif', 
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: '1px',
      color: 'aliceblue',
      marginLeft:'auto', 
  
      [theme.breakpoints.up('sm')]: {
        width: '18rem',
        fontSize: '14px',
        lineHeight: '25px',
        letterSpacing: '2px',
      },
      [theme.breakpoints.up('md')]: {
        width: '24rem',
        lineHeight: '30px',
        letterSpacing: '2px',
      },
      [theme.breakpoints.up('lg')]: {
        width: '35rem',
        fontSize: '24px',
        lineHeight: '40px',
        letterSpacing: '2.5px',
      },
    }));
    
  
  function Hero() {
    return (
      <HeroContainer id="home">
          <HeaderText>
            {/* <Title variant="h5" className="title">Beauty Space</Title> */}
            <Title variant="h5" className="title">
        <span style={gradientBackgroundStyle}>Beauty</span> <span>Space</span>
      </Title>
            <SmallText className="small-text">Be Your Own Kind of Beautiful</SmallText>
          </HeaderText>
      </HeroContainer>
    );
  }
  
  export default Hero;