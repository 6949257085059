import { createTheme } from '@mui/material/styles'; 

const navbarTheme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#030403', 
        },
      },
    },
  },
});

export default navbarTheme;
