import React from 'react';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import video1 from '../assets/v1.mp4'; // Import video files
import video2 from '../assets/v2.mp4';
import video3 from '../assets/v3.mp4';

const VideoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  width: '92%',
  margin: 'auto',
  padding: '1rem',
  boxSizing: 'border-box',
});

const Video = styled('video')({
  width: '100%',
  height: 'auto',
});

const VideoComponent = () => {
  return (
    <VideoContainer>
      <Grid container spacing={2}>
        {/* First Video */}
        <Grid item xs={12} sm={6} md={4}>
          <Video loop autoPlay muted>
            <source src={video1} type="video/mp4" />
            Your browser does not support the video tag.
          </Video>
        </Grid>

        {/* Second Video */}
        <Grid item xs={12} sm={6} md={4}>
          <Video loop autoPlay muted>
            <source src={video2} type="video/mp4" />
            Your browser does not support the video tag.
          </Video>
        </Grid>

        {/* Third Video */}
        <Grid item xs={12} sm={6} md={4}>
          <Video loop autoPlay muted>
            <source src={video3} type="video/mp4" />
            Your browser does not support the video tag.
          </Video>
        </Grid>
      </Grid>
    </VideoContainer>
  );
}

export default VideoComponent;
