

import React from "react";
import { Grid, Card, CardMedia, Typography } from '@mui/material';
import { styled } from '@mui/system'; // Import styled from @mui/system

// Import images
import hairSalonImage from '../assets/imageServices.jpg';
import manicureImage from '../assets/n11.jpg';
import pedicureImage from '../assets/p1.jpg';
import eyelashImage from '../assets/b.jpg';

// Define custom styled components
const CustomCard = styled(Card)({
  margin: '0.1rem',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const CustomCardMedia = styled(CardMedia)({
  width: '100%',
  height: '200px',
  objectFit: 'cover', 
  animation: '$toColor 10s',
});

const CustomTypography = styled(Typography)({
  height: '30px',
  backgroundSize: 'cover',
  paddingTop: '10px',
  fontSize: 'small',
  borderRadius: '2px',
  textTransform: 'uppercase',
  textAlign: 'center',
  marginTop: '1rem', // equivalent to theme.spacing(1)
  marginBottom: '1rem', // equivalent to theme.spacing(1)
  '&:hover': {
    fontSize: '14px',
    boxShadow: '2px 2px 5px rgba(140, 136, 136, 0.5)',
  },
});


const Services = () => {
  return (
    <Grid container sx={{
      width: '90%',
      height: 'auto',
      position: 'relative',
      margin: 'auto',
      
      padding: 0,
      marginBottom: '2rem',
      marginTop:{
      xs:'20px',
      md:'50px'
      },
   zIndex:'100'
    }} id="serfoto">
      <Typography className="title text-center" sx={{ fontSize:{
xs:'14px',
md:'16px',
      },
         margin: 'auto', padding:'none' }}>
        VÅRA TJÄNSTER
      </Typography>

      <Grid container justifyContent="center" spacing={2} sx={{ marginTop: '5px' }}>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <CustomCard className="card-1">
            <CustomCardMedia
              component="img"
              image={hairSalonImage}
              alt="Hårsalongstjänst"
              className="foto-card"
            />
            <CustomTypography variant="h3" className="name-pics sf">
              Hårsalongstjänst
            </CustomTypography>
          </CustomCard>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2}>
          <CustomCard className="card-3">
            <CustomCardMedia
              component="img"
              image={manicureImage}
              alt="Lyxig manikyr"
              className="foto-card"
            />
            <CustomTypography variant="h3" className="name-pics sf">
              Lyxig manikyr
            </CustomTypography>
          </CustomCard>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2}>
          <CustomCard className="card-3">
            <CustomCardMedia
              component="img"
              image={pedicureImage}
              alt="Lyxig pedikyr"
              className="foto-card"
            />
            <CustomTypography variant="h3" className="name-pics sf">
              Lyxig pedikyr
            </CustomTypography>
          </CustomCard>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2}>
          <CustomCard className="card-3">
            <CustomCardMedia
              component="img"
              image={eyelashImage}
              alt="Fransar & Bryn"
              className="foto-card"
            />
            <CustomTypography variant="h3" className="name-pics sf">
              Fransar & Bryn
            </CustomTypography>
          </CustomCard>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Services;
